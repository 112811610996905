import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {BuybuyComponent} from './page/buybuy/buybuy.component'
import { AuthGuard } from '../app/_core/app-auth/service/auth.guard'

//import { AuthLoginComponent } from '../app/_core/app-auth/page/auth-login/auth-login.component'
const routes: Routes = [
  {
   /* path: 'login',
    component: AuthLoginComponent,*/
    path: 'login',
    loadChildren: () => import('./_core/app-auth/app-auth.module').then(m => m.AppAuthModule),
    data: { title: 'Login Pathox' }



  },
 // { path: '',   redirectTo: '/it', pathMatch: 'full' }, // redirect to `first-component`
  {
    path: '',
     component: BuybuyComponent,
   // canActivate: [AuthGuard],
    data: { title: 'buybuy.app', },
    children: [
      {
        path: '',
        loadChildren: () => import('./_module/dashboard/dashboard.module').then(m => m.DashboardModule),
      },


    ]
  },
 

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
