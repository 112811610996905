
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { NgModule ,LOCALE_ID,NgModuleFactoryLoader, SystemJsNgModuleLoader,} from '@angular/core';
import {MaterialModule} from './_core/material/material.module'
import { FormsModule,  FormGroup, Validators ,  ReactiveFormsModule} from '@angular/forms';
import {AppAuthModule} from './_core/app-auth/app-auth.module'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeDeAt from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
//import {DashboardModule} from './_module/dashboard/dashboard.module';
//import {PazientiModule} from './_module/pazienti/pazienti.module';
import { MenuComponent } from './shared/menu/menu.component';
import { HeaderComponent } from './shared/header/header.component';
import { BuybuyComponent } from './page/buybuy/buybuy.component'
import { ModalDialogComponent } from './shared/modal/modal-dialog/modal-dialog.component';
import { ModalConfirmComponent } from './shared/modal/modal-confirm/modal-confirm.component';
import { LazyLoadImageModule } from 'ng-lazyload-image'; 
import { environment } from '../environments/environment';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';
import { ServiceWorkerModule } from '@angular/service-worker';
import { RouterModule } from '@angular/router';

registerLocaleData(localeDeAt);
@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HeaderComponent,
    BuybuyComponent,
    ModalDialogComponent,
    ModalConfirmComponent,
  ],
  imports: [
  // zajac-->//  AppAuthModule,
    MaterialModule,
    
    FormsModule,
    ReactiveFormsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LazyLoadImageModule,
   // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
   ShareButtonsModule,
  ShareIconsModule,
  AngularFireFunctionsModule,
  ServiceWorkerModule.register('ngsw-worker.js', { enabled: true,registrationStrategy: 'registerImmediately' }),
  RouterModule,
  ],
  exports:[
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    ServiceWorkerModule
  ],
  entryComponents: [
    ModalDialogComponent,
    ModalConfirmComponent
  ],
  providers: [ 
       {provide: NgModuleFactoryLoader , useClass:SystemJsNgModuleLoader},
        { provide: LOCALE_ID, useValue: "it-IT" },
       { provide: REGION, useValue: 'europe-west1' }
      ],
  bootstrap: [AppComponent]
})
export class AppModule { }
