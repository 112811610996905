<mat-nav-list role="navigation" [ngClass]="data?'opened':''">
    <mat-list-item routerLinkActive="active" [routerLinkActiveOptions]="{exact:
        true}">
        <a matLine matTooltipPosition="after" routerLink="/app">
            <mat-icon class="material-icons-outlined">home</mat-icon>
            <span>Home</span>
        </a>
    </mat-list-item>
    <mat-list-item routerLinkActive="active">
        <a matLine matTooltipPosition="after" routerLink="dashboard">
            <mat-icon class="material-icons-outlined">dashboard</mat-icon>
            <span>Dashboard</span>
        </a>
    </mat-list-item>





</mat-nav-list>