import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @Input() data: any;
  constructor() { 
   // this.data= data;
  }

  ngOnInit(): void {
  }

}
