import { Component, OnInit, ChangeDetectorRef, ViewChild, PLATFORM_ID, Inject } from '@angular/core';
import { LoaderService, SnackBarService } from '../../service/loader/loader.service'
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarType, SnackBarBox, PageParams, ServiceObj } from '../../_dto/dto'
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { GenericService } from 'src/app/service/generic/generic.service';
import { MatInput } from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-buybuyapp',
  templateUrl: './buybuy.component.html',
  styleUrls: ['./buybuy.component.scss']
})
export class BuybuyComponent implements OnInit {
  opened: boolean = true;
  showLoader: boolean = false; // numero di richieste in essequzione 
  notificationList: SnackBarBox[] = [];
  subject = new Subject()
  currentPage: number;
  maxPage: number = 1;
  elementsOnpage: number = 25;
  orderBy = '1';
  inputFromUrl : boolean = false;
  @ViewChild(MatInput) input: MatInput;

  constructor(
    private loader: LoaderService, 
    private notificate: SnackBarService, 
    private cdRef: ChangeDetectorRef, 
    public snackBar: MatSnackBar,
    private genericService: GenericService,
    private activeroute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router
    ) { 



    }

  async ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {

      this.genericService.genericObj.subscribe(_item=>{
        if(_item.name=='searchFromUrl')
        {
     
          this.input.value= _item.obj
        }
      })
    }

  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.loader.run.subscribe(item => {

      if (item > 0) {
        this.showLoader = true;
      } else {
        this.showLoader = false;
      }
      this.cdRef.detectChanges();
    })

    this.notificate.run.subscribe(item => {
    
      this.notificationList.push(item)
      if (this.notificationList.length == 1) {
        this.openSnackBar(this.notificationList[0])
       }
    })
  }


  async search(search: string) {
   
    if (search == null || search.length > 2) {
      this.currentPage = 0;

      let _params: PageParams = {
        limit: this.elementsOnpage,
        page: this.currentPage,
        orderby: this.orderBy,
        search: search, 
        
      }
   
      let _item: ServiceObj = new ServiceObj;
      _item.name="search";
      _item.obj= _params;
    this.genericService.pushObj(_item)
     // this.currentPage = 1;

    }

  }
  cleanInput(){
    this.input.value = null;
    let _item: ServiceObj = new ServiceObj;
    _item.name="cleanSearch";
    _item.obj= null;
  this.genericService.pushObj(_item)
  }

  openSnackBar(snack:SnackBarBox) {

    this.snackBar.open(snack.messaggio, null, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
        panelClass: snack.tipo,
    }).afterDismissed().subscribe(x => {
      this.notificationList.shift();
      if(this.notificationList.length>0){
        this.openSnackBar(this.notificationList[0]);
      }
    });

  }

  goToLink() {
    window.open("https://buybuy.app", "_self");
  }


}
