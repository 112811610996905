// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
 apiUrl:'https://api.buybuy.app/',
  //apiUrl:'https://europe-west1-probanest.cloudfunctions.net/api/',
 // apiUrl:'https://europe-west1-probauniversalbuybuy.cloudfunctions.net/api/',
 // apiUrl:'https://europe-west1-dev-buybuy-app.cloudfunctions.net/api/',
 // apiUrl:'https://apibuybuyapp.cristoforozajac.vercel.app/',
 // apiUrl:'http://localhost:3200/',
  requestPath:'',
  server:"test",
  //** Propieta che indica dove è salvato il token localStorage o sessionStaorage */
 // saveIn: "localStorage"
 saveIn: "sessionStorage",

 //* nome del Token da cercare nel Header */
 tokenName:"X-Token"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
