import { Injectable } from '@angular/core';
import {  Subject } from 'rxjs'
import {ServiceObj} from '../../_dto/dto'
@Injectable({
  providedIn: 'root'
})
export class GenericService {

  private obj = new Subject<ServiceObj>();
  genericObj = this.obj.asObservable();
  pushObj(item:ServiceObj) {
    this.obj.next(item)
  }
}
