import { Injectable, PLATFORM_ID } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { SnackBarType, SnackBarBox } from '../../_dto/dto'
class LocalStorage {
  [name: string]: any;
  readonly length: number;
  clear(): void { }
  getItem(key: string): string | null { return undefined; }
  key(index: number): string | null { return undefined; }
  removeItem(key: string): void { }
  setItem(key: string, value: string): void { }
}
@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private load = new Subject<any>();
  run = this.load.asObservable();
  sessionStorage;
  constructor() {
    if (!isPlatformBrowser(PLATFORM_ID)) {
      this.sessionStorage = new LocalStorage()
    } else {
      this.sessionStorage = sessionStorage;
    }
  }


  start() {
    if (!isPlatformBrowser(PLATFORM_ID)) {
      // numero di loaders attivi
      let _x: number = Number(this.sessionStorage.getItem("loaders")) || 0;

      _x = _x + 1;
      if (_x > 15) {
        _x = 15;
      }
      console.log(_x)
      this.sessionStorage.setItem("loaders", (_x).toString())
      this.load.next(_x)
    }


  }
  stop() {
    if (!isPlatformBrowser(PLATFORM_ID)) {
      let _x: number = Number(this.sessionStorage.getItem("loaders")) || 0;
      _x = _x - 1;
      if (_x < 0) {
        _x = 0;
      }

      console.log(_x)
      this.sessionStorage.setItem("loaders", (_x).toString())
    
      this.load.next(_x)
    }
  }

}
@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  private load = new Subject<any>();
  run = this.load.asObservable();



  ok(message: string) {

    let _msg = new SnackBarBox();
    _msg.messaggio = message;
    _msg.tipo = SnackBarType.ok
    this.load.next(_msg)

  }
  error(message: string) {
    let _msg = new SnackBarBox();
    _msg.messaggio = message;
    _msg.tipo = SnackBarType.error
    this.load.next(_msg)

  }
  warning(message: string) {
    let _msg = new SnackBarBox();
    _msg.messaggio = message;
    _msg.tipo = SnackBarType.warning
    this.load.next(_msg)

  }

}
