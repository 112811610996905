<mat-sidenav-container id="app">
    <!-- <mat-sidenav #sitenav opened mode="side" id="leftSideNav" [style.width.px]="opened?260:70" [ngClass]="opened?'openedSide':'closedSide'">
        <img id="logo" [style.width.px]="opened?115:24" [src]="opened? './assets/img/logo/logo-linfa-b@2x.png': './assets/img/logo/piittogramma-linfa@2x.png'">
        <app-menu [data]='opened'></app-menu>

    </mat-sidenav> -->
    <!-- <mat-sidenav-content [style.marginLeft.px]="opened?260:70"> -->
    <mat-sidenav-content>
        <div class="bar">
            <mat-toolbar>
                <mat-toolbar-row fxLayout="row wrap" fxLayoutGap.xs="0%" fxLayoutAlign="space-around center">
                    <!-- <mat-icon color="accent" (click)="opened=!opened" class="material-icons-outlined">menu</mat-icon>
                <span class="spaceBox"></span>
                <div id="userBox">
                </div> -->
                    <div fxFlex="50" fxLayoutAlign.xs="left" fxFlex.xs="60" id="logoBar" fxLayout="row wrap">
                        <img id="logo" [src]="'./assets/img/logo/buybuy512.png'" style="height: 40px;margin-right: 10px;" (click)="goToLink()">
                        <div (click)="goToLink()" id="logoTxt">
                            <h1> buybuy.app</h1>
                            <h6>le migliore offerte</h6>
                        </div>
                    </div>


                    <div fxFlex="50" fxFlex.xs="40" fxLayoutAlign.xs="right" id="top" fxLayoutAlign="end" id="searchBar">
                        <mat-form-field floatLabel="always" appearance="fill" fxLayoutAlign="end center">


                            <input matInput #input placeholder="Cerca" autocomplete="off" (keyup)="search(input.value)">
                            <mat-icon matPrefix style="margin-right: 5px;">search</mat-icon>
                            <button type="button " mat-button mat-icon-button matSuffix *ngIf="input.value.length>0" (click)="cleanInput()">
                            <mat-icon>close</mat-icon>
                            </button>

                        </mat-form-field>


                    </div>

                </mat-toolbar-row>

            </mat-toolbar>
            <div class="progressBox">
                <mat-progress-bar mode="indeterminate" *ngIf="showLoader"></mat-progress-bar>
            </div>
        </div>
        <div style="padding:1%; margin-top: 65px;">

            <router-outlet></router-outlet>
        </div>

    </mat-sidenav-content>
</mat-sidenav-container>