/*  dto data transfer object  */

/**
 *Enum contiene il nomi di classe css che saranno inseripe per diverso dipo di notifica
 *
 * @export
 * @enum {number}
 */
export enum SnackBarType{
    ok="skOk",
    error="skError",
    warning="skWarning"
}
interface ISnackBarBox{
messaggio: string;
tipo: SnackBarType;
}


/**
 *  Object Notifica ha due propieta
 * .messaggio - testo da visualizzare 
 * .tipo - errore, ok o warning
 * @export
 * @class SnackBarBox
 * @implements {ISnackBarBox}
 */
export class SnackBarBox implements ISnackBarBox{
    messaggio: string;
    tipo: SnackBarType;
    }

     interface IServiceObj {
        name:string;
        obj:any;
    }
    export class ServiceObj implements IServiceObj{
        name:string;
        obj:any; 
    }

    interface IPageParams{
        page:number;
        limit:number;
        orderby?:string;
        search?:string;
        catId?: string;
        catName?:string;

    }
    export class PageParams implements IPageParams{
        page:number;
        limit:number;
        orderby?:string;
        search?:string ;
        catId?: string;
        catName?:string;
    }

    export interface ICategorie{
        name:string,
        idHash:string,
        icons:string
    }